import React from 'react'

import { InputChangedHandler, getOptionElements, DEFAULT_VALUE } from './input'
import { Option } from 'pif-preview'

export interface DropDownProps {
    label: string
    options: Array<Option>
    onChange: InputChangedHandler
    value?: string | number
    defaultText?: string
} 

const rowWidth = 12
const controlWidth = 8
const labelWidth = rowWidth - controlWidth

const generateId = (label: string) => `select-${label.toLowerCase().replace(' ', '')}`

export const DropDown = (props: DropDownProps) => {
    let id = generateId(props.label)
    let value: string | number
    let defaultText: string | undefined

    if (props.value || props.value === 0) {
        value = props.value
        defaultText = undefined
    }
    else {
        value = DEFAULT_VALUE
        defaultText = props.defaultText
    }

    return (
        <div className='form-group row'>
            <label htmlFor={id} className={`col-form-label col-sm-${labelWidth}`}>{props.label}</label>
            <div className={`col-sm-${controlWidth}`}>
                <select id={id} className='form-control' value={value} onChange={(e) => props.onChange(e.target.value)}>
                    {getOptionElements(props.options, defaultText)}
                </select>
            </div>
        </div>
    )
}

export default DropDown

