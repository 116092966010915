import React from 'react'

import { InputChangedHandler, getOptionElements } from './input'
import { Option } from 'pif-preview'

export interface ListBoxProps {
    options: Array<Option>
    onChange: InputChangedHandler
    value?: number
} 

export const ListBox = (props: ListBoxProps) => {
    return (
        <select multiple className='form-control' value={props.value ? [props.value.toString()] : []} onChange={(e) => props.onChange(e.target.value)} size={5} >
            {getOptionElements(props.options)}
        </select>
    )
}

export default ListBox

