import React from 'react'
import Constants from './Constants'
import { Route, Redirect } from 'react-router';

export interface AuthenticationResult {
    firstName: string
    token: string
}

const AUTHENTICATION_RESULT = 'authenticationResult'

export const Authentication = {
    get(): AuthenticationResult | null {
        let stored = localStorage.getItem(AUTHENTICATION_RESULT)

        if (!stored) return null
        else return JSON.parse(stored) as AuthenticationResult
    },

    set(result: AuthenticationResult) {
        if (result) localStorage.setItem(AUTHENTICATION_RESULT, JSON.stringify(result))
    }, 

    clear() {
        localStorage.removeItem(AUTHENTICATION_RESULT)
    }, 

    // for now, if we were able to authenticate into the system, we're authorized for all actions
    isAuthorized(): boolean {
        return !!Authentication.get()
    }
}

export interface AuthenticatedProps {
    path: string
}

export class AuthorizedRoute extends Route {
    render() {
        if (Authentication.isAuthorized()) return super.render()

        else return <Redirect to={{ pathname: Constants.Paths.Web.login }} />
    }
}

export class LoginRoute extends Route {
    render() {
        if (!Authentication.isAuthorized()) return super.render()

        else return <Redirect to={{ pathname: Constants.Paths.Web.selectPif }} />
    }
}
