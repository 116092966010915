import React from 'react';
import { Button, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap'

import { RouteComponentProps } from 'react-router';

import Constants from '../Constants'
import { json } from '../http'

import Loading from '../components/Loading'
import DropDown from '../components/DropDown'
import { InputChangedHandler } from '../components/input'
import AppContext from '../AppContext'

import { HierarchyItem, Option } from 'pif-preview'

export interface CreatePifRoute {
    intakeKey: string
    formTypeKey:string
}

export interface CreatePifProps extends RouteComponentProps<CreatePifRoute> { }

export interface CreatePifState {
    formType: string
    applicationTypeName: string
    intakeKey: string
    bmpsWithoutForms?: Array<HierarchyItem>
    name: string
    baseScore: number
    bmpKey?: string 
}

export class CreatePif extends React.Component<CreatePifProps, CreatePifState> {
    static displayName = CreatePif.name;

    static contextType = AppContext
    context!: React.ContextType<typeof AppContext>

    isLoading = () => !this.state.bmpsWithoutForms

    constructor(props: CreatePifProps) {
        super(props)
        this.state = { applicationTypeName: '', intakeKey: '', name: '', formType:'', baseScore: 0 }
    }

    async componentDidMount() {
        this.context.setFluidWidth(false)

        let intakeKey = this.props.match.params.intakeKey
        let formTypeKey = this.props.match.params.formTypeKey

        let formTypeItem = this.context.formTypes!.find(i => i.id == formTypeKey)!
        let intake = this.context.intakes!.find(i => i.id == intakeKey)!
        let applicationType = this.context.applicationTypes!.find(at => at.id == intake.parentId)!

        let [bmps, assignments] = await Promise.all([
            json.get(Constants.Paths.Api.Hierarchy.Bmps, { id: intakeKey }),
            json.get(Constants.Paths.Api.ProjectInformationForms.Assignments, { id: formTypeKey })
        ])

        let bmpsWithoutForms = bmps.filter(b => !assignments.some(a => a.intakeKey == intakeKey && a.bmpKey == b.id && !!a.formId))

        this.setState({ formType: formTypeItem.name, applicationTypeName: applicationType.name, intakeKey: intake.name, bmpsWithoutForms })
    }

    nameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({name: e.target.value})
    }

    baseScoreChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({baseScore: e.target.valueAsNumber})
    }

    bmpChanged: InputChangedHandler = (bmpKey) => {
        this.setState({ bmpKey })
    }

    createClicked = async () => {
        let intakeKey = this.props.match.params.intakeKey
        let formTypeKey = this.props.match.params.formTypeKey
        let { name, bmpKey, baseScore} = this.state

        if (!name || !bmpKey || isNaN(baseScore)) alert('Please fill in all fields')

        else {
            let helpdata = { intakeKey, bmpKey, name, baseScore }

            try {
                let createdForm = await json.post(Constants.Paths.Api.ProjectInformationForms.Create, { id: formTypeKey, data: helpdata })
                this.props.history.push(Constants.Paths.Web.editPif(createdForm.formId))
            }
            catch (ex) {
                // error should be handled by http module
                alert(ex)
            }
        }
    }

    render() {
        if (this.isLoading()) return <Loading />

        let bmpOptions: Array<Option> = this.state.bmpsWithoutForms!.map(pc => { return { value: pc.id, text: pc.name } })

        return (
            <Row>
                <Col>
                    <h5 className='mt-2'>Project Information Form Details:</h5>
                    <Form>
                        <FormGroup row>
                            <Label for='formType' md={{ size: 4 }}>Form Type: </Label>
                            <Col>
                                <input id='formType' type='text' className='form-control-plaintext' value={this.state.formType} readOnly />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for='applicationType' md={{ size: 4 }}>Application Type: </Label>
                            <Col>
                                <input id='applicationType' type='text' className='form-control-plaintext' value={this.state.applicationTypeName} readOnly />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for='intake' md={{ size: 4 }}>Intake: </Label>
                            <Col>
                                <input id='intake' type='text' className='form-control-plaintext' value={this.state.intakeKey} readOnly />
                            </Col>
                        </FormGroup>
                        <DropDown
                            label='BMP: '
                            options={bmpOptions}
                            value={this.state.bmpKey}
                            onChange={this.bmpChanged}
                            defaultText='-- SELECT --' />
                        <FormGroup row>
                            <Label for='name' md={{ size: 4 }}>Form Name: </Label>
                            <Col>
                                <Input type='text' id='name' value={this.state.name} onChange={this.nameChanged} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for='baseScore' md={{ size: 4 }}>Base Score: </Label>
                            <Col>
                                <Input type='number' id='baseScore' value={this.state.baseScore} onChange={this.baseScoreChanged} />
                            </Col>
                        </FormGroup>
                        <div className='text-right'>
                            <Button onClick={this.createClicked}>Create Form</Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        )
    }
}
