import React from 'react'

import { FormResponsesImportResult } from 'pif-preview'

export interface ImportResponsesReportProps {
    importResult: FormResponsesImportResult
}

export const ImportResponsesReport = (props: ImportResponsesReportProps) => {

    let report: JSX.Element 

    if (props.importResult.importErrors.length > 0) {
        let errorReport = props.importResult.importErrors.map((err, ix) => {
            return <li key={ix}>{err}</li>
        })

        report = (
            <div>
                <h2>Import Error</h2>
                <p>No changes to the database were made due to the following issues: </p>
                <ul>
                    {errorReport}
                </ul>
            </div>
        )
    }

    else
    {
        report = (
            <div>
                Application forms submitted: {props.importResult.applicationsAnswered.join(', ')}.
                <br/>
                Answers deleted: {props.importResult.answersDeleted}.
                <br/>
                Answers created: {props.importResult.answersCreated}.
            </div>
        )
    }

    return report
}


