import React from 'react'
import { Option } from 'pif-preview'

export interface InputChangedHandler {
    (value: string): void
}



export const DEFAULT_VALUE = ''

export const getOptionElements = (options: Array<Option>, defaultText = '') => {
    let optionElements = options.map((opt, ix) =>
        <option key={ix + 1} value={opt.value} disabled={opt.disabled}>{opt.text}</option>)

    if (defaultText)
        optionElements.unshift(<option key={0} value={DEFAULT_VALUE} disabled hidden>{defaultText}</option>)

    return optionElements
} 

