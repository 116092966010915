import React from 'react'

import { Settings } from './Constants'
import { findById, Hierarchies, HierarchyItem } from 'pif-preview'
import { AuthenticationResult } from './Authentication'

export interface AppContextType extends Hierarchies {
    settings: Settings

    // needed
    setFluidWidth(fullScreen?: boolean): void
    // needed
    findById(hierarchy: keyof Hierarchies, id: string): HierarchyItem

    login: (authenticationResult: AuthenticationResult) => void
    logout: () => void
}

export const AppContext = React.createContext<AppContextType>({
    settings: { environment: '', dataExchangeDB: '', dirtDB: '', capDB: '', version: '' },
    formTypes:[],
    applicationTypes: [],
    intakes: [],
    bmps: [],
    findById, 
    setFluidWidth: () => null,
    login: () => null,
    logout: () => null,
})

export default AppContext
